import React, { type FC } from 'react';

import { Button, Stack } from '@grafana/ui';

import { StackSize } from '@grafana-oncall-app/helpers/consts';

import { Text } from '@grafana-irm/components';
import { css } from '@emotion/css';

interface Props {
  baseUrl: string;
  token: string;
}

export const LinkLoginButton: FC<Props> = (props: Props) => {
  const { baseUrl, token } = props;
  const mobileDeepLink = `grafana://mobile/login/link-login?oncall_api_url=${baseUrl}&token=${token}`;

  return (
    <Stack direction="column" gap={StackSize.lg}>
      <Text type="primary" strong>
        Sign in via deeplink
      </Text>
      <Text type="primary">Make sure to have the app installed</Text>
      <Button
        variant="primary"
        className={styles.connectBtn}
        onClick={() => {
          window.open(mobileDeepLink, '_blank');
        }}
      >
        Connect Mobile App
      </Button>
    </Stack>
  );
};

const styles = {
  connectBtn: css`
    width: fit-content;
  `,
};
